import PropTypes from 'prop-types';

// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { TextField } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/pt-br';

InputDate.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
};

export default function InputDate({ name, label }) {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
          <DateTimePicker
            label={label}
            control={control}
            inputFormat="DD-MM-YYYY HH:mm"
            value={value}
            onChange={(event) => {
              onChange(new Date(event));
            }}
            renderInput={(params) => <TextField {...params} error={!!error} helperText={error?.message} fullWidth />}
          />
        </LocalizationProvider>
      )}
    />
  );
}

import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import { dispatch } from '../store';

const initialState = {
  isLoading: false,
  error: null,
  schedules: [],
  isOpenModal: false,
  selectedScheduleId: null,
  selectedRange: null,
};

const slice = createSlice({
  name: 'schedule',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getSchedulesSuccess(state, action) {
      state.isLoading = false;
      state.schedules = action.payload;
    },

    createScheduleSuccess(state, action) {
      const newSchedule = action.payload;
      state.isLoading = false;
      state.schedules = [...state.schedules, newSchedule];
    },

    updateScheduleSuccess(state, action) {
      const updatedSchedule = action.payload;
      const updatedSchedules = state.schedules.map((schedule) => {
        if (schedule._id === updatedSchedule._id) {
          return updatedSchedule;
        }
        return schedule;
      });

      state.isLoading = false;
      state.schedules = updatedSchedules;
    },

    selectEvent(state, action) {
      const eventId = action.payload;
      state.isOpenModal = true;
      state.selectedEventId = eventId;
    },

    selectRange(state, action) {
      const { start, end } = action.payload;
      state.isOpenModal = true;
      state.selectedRange = { start, end };
    },
    openModal(state) {
      state.isOpenModal = true;
    },

    closeModal(state) {
      state.isOpenModal = false;
      state.selectedScheduleId = null;
      state.selectedRange = null;
    },
  },
});

export default slice.reducer;

export const { openModal, closeModal, selectEvent } = slice.actions;

export function getEvents(trunkId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/video-calls/schedules/${trunkId}`);
      dispatch(slice.actions.getSchedulesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function checkAvailability(agentId, startTime, endTime) {
  return async () => {
    try {
      const response = await axios.post('/api/schedule/availability', {
        agentId,
        startTime,
        endTime,
      });
      return response.data.available;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return false;
    }
  };
}

export function createEvent(scheduleData) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/api/schedule', scheduleData);
      dispatch(slice.actions.createScheduleSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateEvent(scheduleId, companyId, status, userId, reason) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.patch(`/api/schedule/${scheduleId}/status`, {
        companyId,
        status,
        userId,
        reason,
      });
      dispatch(slice.actions.updateScheduleSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function rescheduleSchedule(scheduleId, companyId, startTime, endTime) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`/api/schedule/${scheduleId}/reschedule`, {
        companyId,
        startTime,
        endTime,
      });
      dispatch(slice.actions.updateScheduleSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function selectRange(start, end) {
  return async () => {
    dispatch(
      slice.actions.selectRange({
        start: start.getTime(),
        end: end.getTime(),
      })
    );
  };
}

export function deleteEvent(scheduleId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete(`/api/schedule/${scheduleId}`);
      dispatch(slice.actions.createScheduleSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

import PropTypes from 'prop-types';
import { useState } from 'react';
// @mui
import { styled } from '@mui/material/styles';
import { List, Box, ListSubheader, Tabs, Tab, Container } from '@mui/material';
//
import { NavListRoot } from './NavList';
// Auth
import useAuth from '../../../hooks/useAuth';
// components
import Iconify from '../../Iconify';
import { PATH_DASHBOARD } from '../../../routes/paths';

// ----------------------------------------------------------------------

export const ListSubheaderStyle = styled((props) => <ListSubheader disableSticky disableGutters {...props} />)(
  ({ theme }) => ({
    ...theme.typography.overline,
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    color: theme.palette.text.primary,
    transition: theme.transitions.create('opacity', {
      duration: theme.transitions.duration.shorter,
    }),
  })
);

// ----------------------------------------------------------------------

NavSectionVertical.propTypes = {
  isCollapse: PropTypes.bool,
  navConfig: PropTypes.array,
};

export default function NavSectionVertical({ navConfig, isCollapse = false, ...other }) {
  const { user } = useAuth();
  const [currentTab, setCurrentTab] = useState('Chat');

  const handleChangeTab = (newValue) => {
    setCurrentTab(newValue);
  };

  const PabxConfig = [
    {
      subheader: 'Relatórios',
      items: [
        {
          title: 'Geral',
          path: PATH_DASHBOARD.pabx.geral,
          icon: <Iconify width={15} height={15} icon={'ic:baseline-list'} />,
        },
        {
          title: 'Estatistícas', // pagina que vai puxar o iframe
          path: PATH_DASHBOARD.pabx.generalStatistics,
          icon: <Iconify width={15} height={15} icon={'system-uicons:graph-box'} />,
        },
        {
          title: 'Gravações',
          path: PATH_DASHBOARD.pabx.callRecordings,
          icon: <Iconify width={15} height={15} icon={'bi:mic'} />,
        },
      ],
      role: ['superadmin', 'administrator', 'master'],
    },
  ];

  const NAVIGATION_TABS = [
    {
      value: 'Chat',
      icon: <Iconify icon={'bi:chat'} width={20} height={20} marginRight={1} />,
      component: (
        <>
          {navConfig.map((group) => {
            const shouldDisplayGroup = group?.role.includes(user?.acl.profile);
            if (!shouldDisplayGroup) return null;
            if (group.subheader === 'Campanhas' && !user.featureFlags?.showCampaignsFeature) {
              return null;
            }

            const filteredItems = group.items.filter((list) => {
              const validateFlag = list?.validateFlag ? (user.featureFlags || {})[list.validateFlag] : true;
              if (list.title === 'Online' && user.acl.profile === 'agent') {
                return false;
              }
              return validateFlag;
            });

            return (
              <List key={group.subheader} disablePadding sx={{ px: 2 }}>
                <ListSubheaderStyle
                  sx={{
                    ...(isCollapse && { opacity: 0 }),
                  }}
                >
                  {group.subheader}
                </ListSubheaderStyle>

                {filteredItems.map((list) => (
                  <NavListRoot key={list.title} list={list} isCollapse={isCollapse} />
                ))}
              </List>
            );
          })}
        </>
      ),
    },
    {
      value: 'PABX',
      icon: <Iconify icon={'ph:phone-thin'} width={20} height={20} marginRight={1} />,
      component: (
        <>
          {PabxConfig.filter((group) => group?.role.includes(user?.acl.profile)).map((group) => (
            <List key={group.subheader} disablePadding sx={{ px: 2 }}>
              <>
                {group?.role.includes(user?.acl.profile) && (
                  <>
                    <ListSubheaderStyle
                      sx={{
                        ...(isCollapse && {
                          opacity: 0,
                        }),
                      }}
                    >
                      {group.subheader}
                    </ListSubheaderStyle>

                    {group.items.map((list) => (
                      <NavListRoot key={list.title} list={list} isCollapse={isCollapse} />
                    ))}
                  </>
                )}
              </>
            </List>
          ))}
        </>
      ),
    },
  ];

  return (
    <Box {...other}>
      {['superadmin', 'administrator', 'master'].includes(user?.acl.profile) && !isCollapse ? (
        <Box>
          {user.featureFlags?.showPabx && (
            <Container sx={{ p: 2 }}>
              <Tabs
                sx={{ width: '200px', height: '50px' }}
                value={currentTab}
                scrollButtons="auto"
                variant="scrollable"
                allowScrollButtonsMobile
                onChange={(e, value) => handleChangeTab(value)}
              >
                {NAVIGATION_TABS.map((tab) => (
                  <Tab key={tab.value} disableRipple value={tab.value} icon={tab.icon} label={tab.value} />
                ))}
              </Tabs>
            </Container>
          )}

          {/* Renderiza o conteúdo da aba selecionada no modo expandido */}
          <Box sx={{ minHeight: '200px' }}>
            {NAVIGATION_TABS.map((tab) => (
              <div key={tab.value} style={{ display: currentTab === tab.value ? 'block' : 'none' }}>
                {tab.component}
              </div>
            ))}
          </Box>
        </Box>
      ) : (
        <Box>
          {/* Renderiza o conteúdo da aba selecionada no modo colapsado */}
          {currentTab === 'PABX' && (
            <Box sx={{ minHeight: '200px' }}>
              {PabxConfig.filter((group) => group?.role.includes(user?.acl.profile)).map((group) => (
                <List key={group.subheader} disablePadding sx={{ px: 2 }}>
                  <ListSubheaderStyle
                    sx={{
                      ...(isCollapse && {
                        opacity: 0,
                      }),
                    }}
                  >
                    {group.subheader}
                  </ListSubheaderStyle>

                  {group.items.map((list) => (
                    <NavListRoot key={list.title} list={list} isCollapse={isCollapse} />
                  ))}
                </List>
              ))}
            </Box>
          )}

          {/* Renderiza o conteúdo da aba SomaSac que agora é Chat quando colapsado */}
          {currentTab === 'Chat' && (
            <Box sx={{ minHeight: '200px' }}>
              {navConfig.map((group) => {
                const shouldDisplayGroup = group?.role.includes(user?.acl.profile);
                if (!shouldDisplayGroup) return null;

                const filteredItems = group.items.filter((list) => {
                  const validateFlag = list?.validateFlag ? (user.featureFlags || {})[list.validateFlag] : true;
                  if (list.title === 'Online' && user.acl.profile === 'agent') {
                    return false;
                  }
                  return validateFlag;
                });

                return (
                  <List key={group.subheader} disablePadding sx={{ px: 2 }}>
                    <ListSubheaderStyle
                      sx={{
                        ...(isCollapse && { opacity: 0 }),
                      }}
                    >
                      {group.subheader}
                    </ListSubheaderStyle>

                    {filteredItems.map((list) => (
                      <NavListRoot key={list.title} list={list} isCollapse={isCollapse} />
                    ))}
                  </List>
                );
              })}
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
}

import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
// slices
import chatReducer from './slices/chat';
import dashboardReducer from './slices/dashboard';
import onlineReducer from './slices/online';
import pabxReducer from './slices/pabx';
import calendarReducer from './slices/calendar';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const rootReducer = combineReducers({
  dashboard: dashboardReducer,
  chat: chatReducer,
  online: onlineReducer,
  calendar: calendarReducer,
  pabx: pabxReducer,
});

export { rootPersistConfig, rootReducer };

import React from 'react';
import socketio from 'socket.io-client';
import { SOCKET_URL } from '../config';

const accessToken = window.localStorage.getItem('accessToken');

export const socket = socketio.connect(`${SOCKET_URL}`, {
  autoConnect: false,
  auth: { token: accessToken },
  withCredentials: true,
  reconnection: true,
  reconnectionAttempts: 5,
  reconnectionDelay: 1000,
  timeout: 10000
});
  
export const SocketContext = React.createContext();

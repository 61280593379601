// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import Iconify from '../../../components/Iconify';

// ----------------------------------------------------------------------

const ICONS = {
  chat: <Iconify width={15} height={15} icon={'bxs:chat'} />,
  dashboard: <Iconify width={15} height={15} icon={'bx:tachometer'} />,
  company: <Iconify width={15} height={15} icon={'mdi:company'} />,
  master: <Iconify width={15} height={15} icon={'solar:crown-bold'} />,
};

const navConfig = [
  // APP
  // ----------------------------------------------------------------------
  {
    subheader: 'app',
    items: [
      { title: 'Conversas', path: PATH_DASHBOARD.chat.root, icon: ICONS.chat },
      {
        title: 'Presencial',
        path: PATH_DASHBOARD.inperson.root,
        icon: <Iconify width={15} height={15} icon={'carbon:person'} />,
        validateFlag: 'showPresencial',
      },
      {
        title: 'Contatos',
        path: PATH_DASHBOARD.contacts.root,
        icon: <Iconify width={15} height={15} icon={'fluent:book-contacts-28-filled'} />,
      },
      {
        title: 'Online',
        path: PATH_DASHBOARD.online.root,
        icon: <Iconify width={15} height={15} icon={'fa6-solid:headset'} />,
      },
    ],
    role: ['agent', 'superadmin', 'administrator', 'master', 'supervisor'],
  },
  // EMPRESA
  // ----------------------------------------------------------------------
  {
    subheader: 'Super Admin',
    items: [
      { title: 'Empresas', path: PATH_DASHBOARD.master.company, icon: ICONS.company },
      { title: 'Master', path: PATH_DASHBOARD.master.root, icon: ICONS.master },
    ],
    role: ['master'],
  },
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'Geral',
    items: [{ title: 'Home', path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard }],
    role: ['superadmin', 'administrator', 'master', 'supervisor'],
  },
  // REPORTING
  // ----------------------------------------------------------------------
  {
    subheader: 'Relatórios',
    items: [
      {
        title: 'Estatísticas',
        path: PATH_DASHBOARD.reporting.estatisticas,
        icon: <Iconify width={15} height={15} icon={'system-uicons:graph-box'} />,
      },
      {
        title: 'Históricos',
        path: PATH_DASHBOARD.reporting.atendimentos,
        icon: <Iconify width={15} height={15} icon={'fe:clock'} />,
      },
      {
        title: 'BI',
        path: PATH_DASHBOARD.reporting.bi,
        icon: <Iconify width={15} height={15} icon={'arcticons:microsoft-power-bi'} />,
      },
      {
        title: 'NPS',
        path: PATH_DASHBOARD.config.nps,
        icon: <Iconify width={15} height={15} icon={'arcticons:nps'} />,
      },
      // removido PABX e adicionado em nova const para a tab PABX
    ],
    role: ['superadmin', 'administrator', 'master'],
  },
  {
    subheader: 'Relatório',
    items: [
      {
        title: 'Históricos',
        path: PATH_DASHBOARD.reporting.atendimentos,
        icon: <Iconify width={15} height={15} icon={'fe:clock'} />,
      },
    ],
    role: ['supervisor'],
  },

  //
  //
  {
    subheader: 'Campanhas',
    items: [
      {
        title: 'Campanhas',
        path: PATH_DASHBOARD.campaigns.root,
        icon: <Iconify width={15} height={15} icon={'material-symbols:campaign'} />,
      },
    ],
    role: ['superadmin', 'administrator', 'master'],
  },

  //
  //

  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'Configurações',
    items: [
      {
        title: 'Geral',
        path: PATH_DASHBOARD.config.root,
        icon: <Iconify width={15} height={15} icon={'ic:baseline-list'} />,
      },
      {
        title: 'Mensagens',
        path: PATH_DASHBOARD.config.messages,
        icon: <Iconify width={15} height={15} icon={'bxs:chat'} />,
      },
      {
        title: 'Troncos',
        path: PATH_DASHBOARD.chats.troncos,
        icon: <Iconify width={15} height={15} icon={'fa-solid:sitemap'} />,
      },
      {
        title: 'Bot',
        path: PATH_DASHBOARD.chats.ura,
        icon: <Iconify width={15} height={15} icon={'ant-design:robot-outlined'} />,
      },
      {
        title: 'Transbordo',
        path: PATH_DASHBOARD.config.transhipment,
        icon: <Iconify width={15} height={15} icon={'eos-icons:hourglass'} />,
      },
      {
        title: 'Timeline',
        path: PATH_DASHBOARD.config.timeline,
        icon: <Iconify width={15} height={15} icon={'mdi:stopwatch'} />,
      },
      {
        title: 'Grupos',
        path: PATH_DASHBOARD.config.group,
        icon: <Iconify width={15} height={15} icon={'fa:group'} />,
      },
      {
        title: 'Usuários',
        path: PATH_DASHBOARD.config.users,
        icon: <Iconify width={15} height={15} icon={'solar:user-id-bold'} />,
      },
      {
        title: 'Contatos',
        path: PATH_DASHBOARD.config.contacts,
        icon: <Iconify width={15} height={15} icon={'fa-solid:users'} />,
      },
    ],
    role: ['superadmin', 'administrator', 'master'],
  },
];

export default navConfig;

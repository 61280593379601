import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

function objFromArray(array, key = '_id') {
  return array.reduce((accumulator, current) => {
    accumulator[current[key]] = current;
    return accumulator;
  }, {});
}

const initialState = {
  isLoading: false,
  error: null,
  contacts: { byId: {}, allIds: [] },
  conversations: { byId: {}, allIds: [] },
  activeConversationId: null,
  participants: [],
  recipients: [],
};

const slice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    // GET CONVERSATIONS
    getConversationsSuccess(state, action) {
      const conversations = [...action.payload];
      if (state.activeConversationId !== null) {
        for (let index = 0; index < conversations.length; index += 1) {
          const element = conversations[index];
          if (element._id === state.activeConversationId) {
            element.messages = state.conversations.byId[state.activeConversationId].messages;
          }
        }
      }

      state.conversations.byId = objFromArray(conversations);
      state.conversations.allIds = Object.keys(state.conversations.byId);
    },
    // GET CONVERSATION
    getConversationSuccess(state, action) {
      const conversation = action.payload;

      if (conversation) {
        state.conversations.byId[conversation._id] = conversation;
        state.participants = [conversation.sender];
        state.activeConversationId = conversation._id;
        if (!state.conversations.allIds.includes(conversation._id)) {
          state.conversations.allIds.push(conversation._id);
        }
      } else {
        state.activeConversationId = null;
      }
    },
    // ON SEND MESSAGE
    onSendMessage(state, action) {
      const conversation = action.payload;
      const {
        conversationId,
        uid,
        text,
        from,
        timestamp,
        attachmentType,
        sentByMe,
        attachments = false,
        urlForce = false,
      } = conversation;

      if (urlForce) {
        attachments[0].attachmentType = attachments[0].type;
      }

      const newMessage = {
        text,
        uid,
        from,
        timestamp,
        attachmentType,
        attachments,
        urlForce,
        sentByMe,
      };

      if (!urlForce) {
        delete newMessage.attachments;
        delete newMessage.urlForce;
      }
      if (state.conversations.byId[conversationId] !== undefined) {
        state.conversations.byId[conversationId].messages.push(newMessage);
        state.conversations.byId[conversationId].canReply = true;
      }
    },
    // ON RECIVE MESSAGE
    onReciveMenssage(state, action) {
      const conversation = action.payload;
      const { conversationId, uid, text, from, timestamp, attachments } = conversation;
      const newMessage = {
        text,
        uid,
        from,
        timestamp,
        attachments,
      };
      if (
        state.conversations.allIds.includes(conversationId) &&
        state.conversations.byId[conversationId].messages.includes((e) => e._id === uid) === false
      ) {
        if (state.activeConversationId !== conversationId) {
          state.conversations.byId[conversationId].unreadMessageCount = 1;
        }

        state.conversations.byId[conversationId].messages.push(newMessage);
        state.conversations.byId[conversationId].clientOffline = false;
        if (!state.conversations.byId[conversationId].canReply) {
          state.conversations.byId[conversationId].canReply = true;
        }
        // Move conversation to top
        state.conversations.allIds = state.conversations.allIds.filter((e) => e !== conversationId);
        state.conversations.allIds = [conversationId, ...state.conversations.allIds];
      }
    },
    // ON NEED UPDATE MESSAGE
    onUpdateMessage(state, action) {
      const conversation = action.payload;
      const { conversationId, uid, attachments } = conversation;
      const indexMessage = state.conversations.byId[conversationId].messages.findIndex((e) => e.uid === uid);
      if (state.conversations.allIds.includes(conversationId) && indexMessage !== -1) {
        const message = [...state.conversations.byId[conversationId].messages];
        message[indexMessage].attachments = attachments;
        state.conversations.byId[conversationId].messages = message;
      }
    },
    markConversationAsReadSuccess(state, action) {
      const { conversationId } = action.payload;
      const conversation = state.conversations.byId[conversationId];
      if (conversation) {
        conversation.unreadMessageCount = 0;
      }
    },
    // GET PARTICIPANTS
    getParticipantsSuccess(state, action) {
      const participants = action.payload;
      state.participants = participants;
    },
    // RESET ACTIVE CONVERSATION
    resetActiveConversation(state) {
      state.activeConversationId = null;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { resetActiveConversation, onReciveMenssage, getConversationSuccess, onUpdateMessage } = slice.actions;

// ----------------------------------------------------------------------

export function getConversations(user) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/api/chat/my', {
        companyId: user.companyId,
      });
      dispatch(slice.actions.getConversationsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getConversation(conversationKey) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/chat/${conversationKey}`);
      dispatch(slice.actions.getConversationSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function markConversationAsRead(conversationId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.post(`/api/chat/${conversationId}/mark-as-read`);
      dispatch(slice.actions.markConversationAsReadSuccess({ conversationId }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function saveMessage(message) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      let data = {
        internalCorrelationId: message.uid,
        text: message.text,
      };

      // Messages type docs and videos
      if (
        message.attachmentType !== 'text' &&
        message.attachmentType !== 'audio' &&
        message.attachmentType !== 'template'
      ) {
        const fdata = new FormData();
        fdata.append('internalCorrelationId', message.text);
        if (message.text) fdata.append('text', message.text);
        fdata.append('attachment', message.attachments[0]);
        data = fdata;
        message.urlForce = true;
      }

      // Messages type audio
      if (message.attachmentType === 'audio') {
        const fdats = new FormData();
        fdats.append('internalCorrelationId', message.text);
        fdats.append('text', message.text || '[Audio]');
        fdats.append('attachmentDuration', message.attachmentDuration);
        const fileOfBlob = new File(message.attachments, 'custom-soma1234.ogg', { type: 'audio/ogg;codecs=opus' });

        fdats.append('attachment', fileOfBlob);
        data = fdats;
        message.urlForce = true;
      }
      // Messages type template
      if (message.attachmentType === 'template') {
        const nTempalte = { ...message.template };
        const newParam = [];

        nTempalte.components.forEach((element) => {
          const newParamArray = {
            type: element.type,
            parameters: [],
          };
          if (element.type === 'HEADER') {
            if (element?.format === 'IMAGE') {
              newParamArray.parameters.push({
                type: 'image',
                image: {
                  link: element.example.header_handle[0],
                },
              });
            }
          }
          if (element.type === 'BODY') {
            // Check if is array
            if (element.example && Array.isArray(element.example?.body_text)) {
              element.example.body_text[0].forEach((body) => {
                newParamArray.parameters.push({
                  type: 'text',
                  text: body,
                });
              });
            }
          }
          if (element.type === 'BUTTONS') {
            element.buttons.forEach((button, i) => {
              newParam.push({
                type: 'button',
                sub_type: button.type,
                index: `${i}`,
                parameters: [
                  {
                    type: 'text',
                    text: button.text,
                  },
                ],
              });
            });
            return;
          }
          if (newParamArray.parameters.length > 0) {
            newParam.push(newParamArray);
          }
        });

        nTempalte.components = newParam;
        data.template = nTempalte;
      }

      if(message.requestLocation) {
        data.requestLocation = true;
      }
      // Show in screen in real time
      dispatch(slice.actions.onSendMessage(message));
      // Send to server the menssages
      await axios.post(`/api/chat/${message.conversationId}/send`, data);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

import PropTypes from "prop-types";
import { useState } from "react";
// form
import { useFormContext, Controller } from "react-hook-form";
// @mui
import { TextField } from "@mui/material";

import InputMask from "react-input-mask";

// ----------------------------------------------------------------------

RHFPhone.propTypes = {
  name: PropTypes.string,
  helperText: PropTypes.node,
};

export default function RHFPhone({ name, helperText, ...other }) {
  const { control } = useFormContext();
  const [mask, setMask] = useState("(99) 9999-99999");

  const handleInputChange = (event) => {
    const value = event.target.value.replace(/\D/g, "");
    if (value.length <= 10) {
      setMask("(99) 9999-99999");
    } else {
      setMask("(99) 9 9999-9999");
    }
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <InputMask
          mask={mask}
          maskChar={null}
          {...field}
          onChange={(e) => {
            field.onChange(e);
            handleInputChange(e);
          }}
        >
          {({ inputProps }) => (
            <TextField
              fullWidth
              error={!!error}
              helperText={error ? error?.message : helperText}
              {...inputProps}
              {...other}
            />
          )}
        </InputMask>
      )}
    />
  );
}

import Swal from 'sweetalert2';
// Utils
import toast from './SnackDom';

// redux
import { getDashboard } from '../redux/slices/dashboard';
import { onReciveMenssage, getConversations, onUpdateMessage } from '../redux/slices/chat';
import { getOnlineUsers, UpdateChats, RemoveChats } from '../redux/slices/online';
import { updatePabxState } from '../redux/slices/pabx';
// routes
import { PATH_DASHBOARD } from '../routes/paths';
// Use Soudn
import boopSfx from '../mp3/card.mp3';
import leaveSfx from '../mp3/leave.mp3';

let lastPlayedMessageId = null; // Store the last played message ID

// Functions
const beep = (sound, messageUid) => {
  if (sound === 'new') {
    if (messageUid !== lastPlayedMessageId) {
      // Check for unique message ID
      const snd = new Audio(boopSfx);
      snd.play();
      lastPlayedMessageId = messageUid; // Update last played message ID
    }
  } else if (sound === 'leave') {
    const snd = new Audio(leaveSfx);
    snd.play();
  }
};

const OnConnectCompany = async (message, user, dispatch) => {
  const type = message?.metadata.type;
  const token = window.localStorage.getItem('accessToken');

  if (type === 'tokenExpiration') {
    if (message.data.token && message.data.token === token) {
      await Swal.fire({
        title: 'Token expirado',
        text: 'O token de acesso expirou!',
        type: 'error',
      });
      dispatch({ type: 'LOGOUT' });
      window.location = '/auth/login';
    }
  }
  // QR Code Whatsapp
  if (
    user.acl.profile === 'master' ||
    user.acl.profile === 'supervisor' ||
    user.acl.profile === 'administrator' ||
    user.acl.profile === 'superadmin'
  ) {
    switch (type) {
      case 'whatsAppQrCodeChange': {
        const service = user.services.find((e) => e._id === message.metadata?.serviceId);
        if (service) toast.callqrcode(service.description, message.data.whatsApp.qrCode);
        break;
      }
      case 'serviceStatusUpdate': {
        try {
          const service = user.services.find((e) => e._id === message.metadata?.serviceId);
          if (message.data?.authenticated && message.data?.connected) {
            toast.success(`Tronco ${service.description} conectado com sucesso!`, 1);
          }
          if (!message.data?.connected && message.data?.launched) {
            toast.error(`Tronco ${service.description} sem conexão!`, 1);
          }
        } catch (error) {
          console.log(error);
          toast.error(`Erro ao atualizar informações de tronco.`, 1);
        }
        break;
      }
      default: {
        break;
      }
    }
  }

  // Master Functions
  if (
    user.acl.profile === 'master' ||
    user.acl.profile === 'supervisor' ||
    user.acl.profile === 'administrator' ||
    user.acl.profile === 'superadmin'
  ) {
    switch (type) {
      case 'userConnected': {
        // Add Agent on Informations
        dispatch(getDashboard(user.companyId));

        // If path is online
        if (window.location.pathname.includes(PATH_DASHBOARD.online.root)) {
          dispatch(getOnlineUsers());
        }

        break;
      }
      case 'userDisconnected': {
        // Remove Agent on Informations
        dispatch(getDashboard(user.companyId));
        // If path is online
        if (window.location.pathname.includes(PATH_DASHBOARD.online.root)) {
          dispatch(getOnlineUsers());
        }
        break;
      }
      case 'dashboardUpdate': {
        // Update Dashboard Information
        dispatch(getDashboard(user.companyId));

        break;
      }
      case 'userBreakStart': {
        // If path is online
        if (window.location.pathname.includes(PATH_DASHBOARD.online.root)) {
          dispatch(getOnlineUsers());
        }

        break;
      }
      case 'userBreakEnd': {
        if (window.location.pathname.includes(PATH_DASHBOARD.online.root)) {
          dispatch(getOnlineUsers());
        }
        break;
      }
      case 'chatAssignment': {
        if (window.location.pathname.includes(PATH_DASHBOARD.online.root)) {
          dispatch(UpdateChats(message.data));
        }
        break;
      }
      case 'chatTransfer': {
        if (window.location.pathname.includes(PATH_DASHBOARD.online.root)) {
          dispatch(UpdateChats(message.data));
        }
        break;
      }
      case 'chatFinalization': {
        if (!message.data.chatId) {
          throw new Error('Não foi encontrado o ID do chat');
        }

        if (window.location.pathname.includes(PATH_DASHBOARD.online.root)) {
          dispatch(RemoveChats(message.data));
        }

        break;
      }

      case 'pabxStateUpdate': {
        // Acessa diretamente os arrays de message.data
        const novoArraySips = message.data.sips || []; // Usa um array vazio se não houver dados
        const novoArrayQueues = message.data.queues || [];
        const novoArrayAgents = message.data.agents || [];
        dispatch(
          updatePabxState({
            sips: novoArraySips,
            queues: novoArrayQueues,
            agents: novoArrayAgents,
          })
        );
        break;
      }

      default: {
        break;
      }
    }
  }
  // Read socket msg in all application
  if (
    user.acl.profile === 'agent' ||
    user.acl.profile === 'supervisor' ||
    user.acl.profile === 'administrator' ||
    user.acl.profile === 'superadmin' ||
    user.acl.profile === 'master'
  ) {
    switch (type) {
      case 'chatTransfer': {
        if (message.data.fromUserId === user._id) {
          dispatch(getConversations(user));
        }
        break;
      }

      case 'chatAssignment': {
        if (message.data.agentId === user._id) {
          dispatch(getConversations(user));
          beep('new', message.data.agentId);
        }

        break;
      }

      case 'incomingMessages': {
        if (user._id === message.data.agentId) {
          const fristMessage = message.data.messages[0];
          dispatch(
            onReciveMenssage({
              conversationId: message.data.chatId,
              text: fristMessage.text,
              uid: fristMessage.uid,
              from: fristMessage.from,
              timestamp: fristMessage.timestamp,
              attachments: fristMessage.attachments,
            })
          );
          beep('new', fristMessage.uid);
          // Show notification when user is not in chat

          if (!window.location.pathname.includes(PATH_DASHBOARD.chat.root)) {
            toast.success('Você recebeu uma nova mensagem!');
          }
        }
        break;
      }

      case 'outgoingMessages': {
        break;
      }

      case 'messageUpdate': {
        if (window.location.pathname.includes(message.data.chatId)) {
          const fristMessage = message.data;

          dispatch(
            onUpdateMessage({
              conversationId: message.data.chatId,
              uid: fristMessage.uid,
              from: fristMessage.senderUid.replace('@c.us', ''),
              attachments: fristMessage.attachments,
            })
          );
        }
        break;
      }

      case 'chatFinalization': {
        if (!message.data.chatId) {
          throw new Error('Não foi encontrado o ID do chat');
        }

        if (user._id === message.data.agentId) {
          dispatch(getConversations(user));
          beep('leave');
        }

        break;
      }

      default: {
        break;
      }
    }
  }
};

export { OnConnectCompany };

import { useState, useEffect } from 'react';
// @mui
import { alpha } from '@mui/material/styles';
import {
  Button,
  Dialog,
  Select,
  MenuItem,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  InputLabel,
  FormControl,
  FormHelperText,
  Tooltip,
} from '@mui/material';
// hooks
import useAuth from '../../../hooks/useAuth';
// utils
import axios from '../../../utils/axios';
// components
import Iconify from '../../../components/Iconify';
import { IconButtonAnimate } from '../../../components/animate';

// ----------------------------------------------------------------------

export default function ContactsPopover() {
  // Timer
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  const [open, setOpen] = useState(false);
  const { user, update } = useAuth();

  const [breakReasonsList, setList] = useState([]);
  const [reason, setReason] = useState('');
  const [missingPauseReason, setMissingPauseReason] = useState(false);

  const handleOpen = async () => {
    // Get breakReasons information
    if (!user.onBreak) {
      const response = await axios.get(`/api/company/${user.companyId}`);
      const breakReasons = response.data?.config.breakReasons;
      setList(breakReasons);
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    setReason(event.target.value);
  };

  const handlePause = async () => {
    // Send break information
    if (!reason) {
      setMissingPauseReason(true);
      return;
    }
    const response = await axios.post(`/api/user/${user._id}/break`, {
      onBreak: true,
      breakReason: reason,
    });

    setMissingPauseReason(false);
    setOpen(false);
    if (response.status === 200) {
      update(response.data);
    }
  };

  const handleStart = async () => {
    setOpen(false);
    // Send break information
    const response = await axios.post(`/api/user/${user._id}/break`, {
      onBreak: false,
    });
    if (response.status === 200) {
      update(response.data);
    }
  };

  useEffect(() => {
    const getTime = () => {
      let d = Date.parse(user?.breakStart);
      const now = Date.now();

      if (d > now) {
        d = now;
      }
      const time = now - d;

      setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
      setMinutes(Math.floor((time / 1000 / 60) % 60));
      setSeconds(Math.floor((time / 1000) % 60));
    };

    if (user?.onBreak) {
      const interval = setInterval(() => getTime(user?.breakStart), 1000);

      return () => clearInterval(interval);
    }

    return false;
  }, [user]);

  return (
    <>
      {user?.onBreak ? (
        <>
          <Button
            variant="outlined"
            style={{ background: '#ff9a03', color: `white`, padding: `2px 15px`, border: `none`, width: 125 }}
            startIcon={<Iconify icon={'bi:pause-btn-fill'} width={30} height={30} />}
            height={10}
            onClick={handleOpen}
          >
            <div>
              <div style={{ fontSize: `0.86rem` }}>
                {hours < 10 ? `0${hours}` : hours}:{minutes < 10 ? `0${minutes}` : minutes}:
                {seconds < 10 ? `0${seconds}` : seconds}
              </div>
              <Tooltip title={user.breakReason}>
                <small
                  style={{
                    display: `block`,
                    fontSize: `0.6rem`,
                    whiteSpace: `nowrap`,
                    textOverflow: `ellipsis`,
                    overflow: `hidden`,
                    opacity: `0.75`,
                    maxWidth: 60,
                    width: 60,
                  }}
                >
                  {user.breakReason}
                </small>
              </Tooltip>
            </div>
          </Button>
          <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Remover pausa?</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description" style={{ marginTop: 5 }}>
                Deseja realmente registrar a remoção da pausa?.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="inherit">
                Não
              </Button>
              <Button onClick={handleStart} variant="contained" autoFocus>
                Sim
              </Button>
            </DialogActions>
          </Dialog>
        </>
      ) : (
        <>
          <IconButtonAnimate
            color={open ? 'primary' : 'warning'}
            onClick={handleOpen}
            sx={{
              width: 50,
              height: 50,
              ...(open && {
                bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.focusOpacity),
              }),
            }}
          >
            <Iconify icon={'bi:pause-btn-fill'} width={30} height={30} />
          </IconButtonAnimate>
          {/* Dialog pause context  */}
          <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Pausar</DialogTitle>
            <DialogContent style={{ marginTop: 10 }}>
              <DialogContentText>
                Parece que você precisa fazer uma pausa. Sabemos como é importante se cuidar e recarregar as energias.
                Por favor, compartilhe conosco o motivo da sua pausa para podermos lhe ajudar melhor quando você voltar.
              </DialogContentText>

              <FormControl required sx={{ minWidth: '100%', marginTop: 2 }}>
                {missingPauseReason && (
                  <FormHelperText
                    error
                    sx={{ display: 'flex', justifyContent: 'center', fontWeight: 'bold', fontSize: 14 }}
                  >
                    O motivo da pausa é obrigatório
                  </FormHelperText>
                )}

                <InputLabel id="motivo-pausa">Motivo</InputLabel>
                <Select
                  labelId="motivo-pausa"
                  id="motivo-pausa-id"
                  label="Motivo"
                  value={reason}
                  onChange={handleChange}
                >
                  {breakReasonsList.map((e, i) => (
                    <MenuItem key={i} value={e}>
                      {e}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="inherit">
                Cancelar
              </Button>
              <Button onClick={handlePause} variant="contained">
                Iniciar Pausa
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </>
  );
}

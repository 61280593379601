import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { PATH_AFTER_LOGIN, PATH_AFTER_LOGIN_AGENT } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';
// hooks
import useAuth from '../hooks/useAuth';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  const { user } = useAuth();
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'verify', element: <VerifyCode /> },
      ],
    },
    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          element: <Navigate to={user?.acl.profile === 'agent' ? PATH_AFTER_LOGIN_AGENT : PATH_AFTER_LOGIN} replace />,
          index: true,
        },
        {
          path: 'app',
          element: (
            <RoleBasedGuard
              accessibleRoles={['superadmin', 'administrator', 'master', 'supervisor']}
              children={<GeneralApp />}
            />
          ),
        },
        {
          path: 'company',
          children: [
            { element: <RoleBasedGuard accessibleRoles={['master']} children={<Company />} />, index: true },
            { path: 'new', element: <RoleBasedGuard accessibleRoles={['master']} children={<CompanyCreate />} /> },
            {
              path: ':name/edit',
              element: <RoleBasedGuard accessibleRoles={['master']} children={<CompanyCreate />} />,
            },
          ],
        },
        {
          path: 'master',
          element: <RoleBasedGuard accessibleRoles={['master']} children={<MasterList />} />,
        },
        {
          path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user/profile" replace />, index: true },
            {
              path: 'list',
              element: (
                <RoleBasedGuard accessibleRoles={['superadmin', 'administrator', 'master']} children={<UserList />} />
              ),
            },
            {
              path: 'new',
              element: (
                <RoleBasedGuard accessibleRoles={['superadmin', 'administrator', 'master']} children={<UserCreate />} />
              ),
            },
            {
              path: ':name/edit',
              element: (
                <RoleBasedGuard accessibleRoles={['superadmin', 'administrator', 'master']} children={<UserCreate />} />
              ),
            },
            {
              path: 'account',
              element: (
                <RoleBasedGuard
                  accessibleRoles={['superadmin', 'administrator', 'master', 'supervisor', 'agent']}
                  children={<UserAccount />}
                />
              ),
            },
          ],
        },
        {
          path: 'chat',
          children: [
            { element: <Chat />, index: true },
            { path: 'new', element: <Chat /> },
            { path: ':conversationKey', element: <Chat /> },
          ],
        },
        {
          path: 'inperson',
          children: [{ element: <InPerson />, index: true }],
        },
        {
          path: 'calls',
          children: [
            { element: <CardAtendimentos />, index: true },
            { path: ':id', element: <CardAtendimentos /> },
          ],
        },
        {
          path: 'contacts',
          children: [{ element: <ContactUser />, index: true }],
        },
        {
          path: 'nps',
          element: (
            <RoleBasedGuard accessibleRoles={['superadmin', 'administrator', 'master']} children={<NPSpage />} />
          ),
        },
        {
          path: 'chats',
          children: [
            {
              path: 'troncos',
              element: (
                <RoleBasedGuard accessibleRoles={['superadmin', 'administrator', 'master']} children={<Troncos />} />
              ),
            },
            {
              path: 'troncos/new',
              element: (
                <RoleBasedGuard accessibleRoles={['superadmin', 'administrator', 'master']} children={<TroncosAdd />} />
              ),
            },
            {
              path: 'troncos/:troncoKey',
              element: (
                <RoleBasedGuard
                  accessibleRoles={['superadmin', 'administrator', 'master']}
                  children={<TroncosEdit />}
                />
              ),
            },
            {
              path: 'ura',
              element: (
                <RoleBasedGuard accessibleRoles={['superadmin', 'administrator', 'master']} children={<UraList />} />
              ),
            },
            {
              path: 'ura/new',
              element: (
                <RoleBasedGuard accessibleRoles={['superadmin', 'administrator', 'master']} children={<UraCreate />} />
              ),
            },
            {
              path: 'ura/:name/edit',
              element: (
                <RoleBasedGuard accessibleRoles={['superadmin', 'administrator', 'master']} children={<UraCreate />} />
              ),
            },
          ],
        },
        {
          path: 'config',
          children: [
            {
              element: (
                <RoleBasedGuard
                  accessibleRoles={['superadmin', 'administrator', 'master']}
                  children={<GeneralConfig />}
                />
              ),
              index: true,
            },
            {
              path: 'messages',
              element: (
                <RoleBasedGuard
                  accessibleRoles={['superadmin', 'administrator', 'master']}
                  children={<MessagesConfig />}
                />
              ),
            },
            {
              path: 'transhipment',
              element: (
                <RoleBasedGuard
                  accessibleRoles={['superadmin', 'administrator', 'master']}
                  children={<TransbordoConfig />}
                />
              ),
            },
            {
              path: 'timeline',
              element: (
                <RoleBasedGuard
                  accessibleRoles={['superadmin', 'administrator', 'master']}
                  children={<TimeLineConfig />}
                />
              ),
            },
            {
              path: 'group',
              element: (
                <RoleBasedGuard
                  accessibleRoles={['superadmin', 'administrator', 'master']}
                  children={<GroupConfig />}
                />
              ),
            },
            {
              path: 'group/new',
              element: (
                <RoleBasedGuard
                  accessibleRoles={['superadmin', 'administrator', 'master']}
                  children={<GroupCreate />}
                />
              ),
            },
            {
              path: 'group/:name/edit',
              element: (
                <RoleBasedGuard
                  accessibleRoles={['superadmin', 'administrator', 'master']}
                  children={<GroupCreate />}
                />
              ),
            },
            {
              path: 'contacts',
              element: (
                <RoleBasedGuard
                  accessibleRoles={['superadmin', 'administrator', 'master']}
                  children={<ContactsConfig />}
                />
              ),
            },
            {
              path: 'users',
              element: (
                <RoleBasedGuard accessibleRoles={['superadmin', 'administrator', 'master']} children={<UserList />} />
              ),
            },
          ],
        },
        {
          path: 'reporting',
          children: [
            { path: 'atendimentos', element: <ReportHistory /> },

            { path: 'estatisticas', element: <GraphicsDashboard /> },

            { path: 'bi', element: <GraphicsBi /> }, // NOVA TELA ------------------------------------
          ],
        },
        {
          path: 'pabx',
          children: [
            { path: 'graphic', element: <GraphicsPabx /> },
            { path: 'general-statistics', element: <GeneralStatistics /> },
            { path: 'call-recording', element: <CallRecording /> },
            { path: 'geral', element: <Geral /> },
          ],
        },
        {
          path: 'online',
          children: [
            {
              path: 'users',
              element: (
                <RoleBasedGuard
                  accessibleRoles={['superadmin', 'administrator', 'master', 'supervisor']}
                  children={<OnlineUsers />}
                />
              ),
            },
            {
              path: 'users/:id/view',
              element: (
                <RoleBasedGuard
                  accessibleRoles={['superadmin', 'administrator', 'master', 'supervisor']}
                  children={<OnlineUsers />}
                />
              ),
            },
          ],
        },
      ],
    },
    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoon /> },
        { path: 'maintenance', element: <Maintenance /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: '/',
      element: (
        <GuestGuard>
          <Login />
        </GuestGuard>
      ),
    },
    { path: 'evaluation/:chatId', element: <Evaluation /> },
    { path: 'totem/:tokenId', element: <TotemInPerson /> },
    { path: 'painelficha/:tokenId', element: <PainelInPerson /> },
    { path: 'widget/:serviceId', element: <WidgetWebChat /> },

    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));
// Dashboard
const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralApp')));
// const UserProfile = Loadable(lazy(() => import('../pages/dashboard/UserProfile')));
// const UserCards = Loadable(lazy(() => import('../pages/dashboard/UserCards')));
const UserList = Loadable(lazy(() => import('../pages/dashboard/UserList')));
const UserAccount = Loadable(lazy(() => import('../pages/dashboard/UserAccount')));
const UserCreate = Loadable(lazy(() => import('../pages/dashboard/UserCreate')));
const Chat = Loadable(lazy(() => import('../pages/dashboard/Chat')));
const Troncos = Loadable(lazy(() => import('../pages/dashboard/Troncos')));
const TroncosAdd = Loadable(lazy(() => import('../pages/dashboard/TroncoAdd')));
const TroncosEdit = Loadable(lazy(() => import('../pages/dashboard/TroncoEdit')));
const ContactUser = Loadable(lazy(() => import('../pages/dashboard/ContactUser')));
// Master
const Company = Loadable(lazy(() => import('../pages/dashboard/Company')));
const CompanyCreate = Loadable(lazy(() => import('../pages/dashboard/CompanyCreate')));
const MasterList = Loadable(lazy(() => import('../pages/dashboard/MasterList')));
// Config
const GeneralConfig = Loadable(lazy(() => import('../pages/dashboard/GeneralConfig')));
const MessagesConfig = Loadable(lazy(() => import('../pages/dashboard/Messages')));
const TransbordoConfig = Loadable(lazy(() => import('../pages/dashboard/Transbordo')));
const TimeLineConfig = Loadable(lazy(() => import('../pages/dashboard/TimeLine')));
const GroupConfig = Loadable(lazy(() => import('../pages/dashboard/Group')));
const GroupCreate = Loadable(lazy(() => import('../pages/dashboard/GroupCreate')));
const ContactsConfig = Loadable(lazy(() => import('../pages/dashboard/Contacts')));
const NPSpage = Loadable(lazy(() => import('../pages/dashboard/Nps')));
// Reports
const ReportHistory = Loadable(lazy(() => import('../pages/dashboard/ReportHistory')));
const GraphicsBi = Loadable(lazy(() => import('../pages/dashboard/GraphicsBi')));
// PABX
const GraphicsPabx = Loadable(lazy(() => import('../pages/dashboard/pabx/GraphicsPabx')));
const GeneralStatistics = Loadable(lazy(() => import('../pages/dashboard/pabx/GeneralStatistics')));
const CallRecording = Loadable(lazy(() => import('../pages/dashboard/pabx/CallRecording')));
const Geral = Loadable(lazy(() => import('../pages/dashboard/pabx/Geral')));
// Calls
const CardAtendimentos = Loadable(lazy(() => import('../pages/dashboard/CardAtendimetos')));
const GraphicsDashboard = Loadable(lazy(() => import('../pages/dashboard/Graphics')));
// InPerson
const InPerson = Loadable(lazy(() => import('../pages/dashboard/InPerson')));

// BOT
const UraList = Loadable(lazy(() => import('../pages/dashboard/Ura')));
const UraCreate = Loadable(lazy(() => import('../pages/dashboard/UraCreate')));
// Main
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Evaluation = Loadable(lazy(() => import('../pages/Evaluation')));
const TotemInPerson = Loadable(lazy(() => import('../pages/inperson/Totem')));
const PainelInPerson = Loadable(lazy(() => import('../pages/inperson/Painel')));
const WidgetWebChat = Loadable(lazy(() => import('../pages/WidgetWebChat')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
// Online Users
const OnlineUsers = Loadable(lazy(() => import('../pages/dashboard/OnlineUsers')));
